.logos__section{
    padding: 100px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
}

.logos__container{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
}

.title__companies{
    font-size: 48px;
    font-weight: bold;
    color: #123BA5;
    align-self: center;
    text-align: center;
    margin-bottom: 82px;
    text-transform: uppercase;
}
.carousel__container{
    width: 90vw;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
}
.img_logos{
    filter: saturate(0);
    max-width: 100%;
    transition: all 0.3s ease-out;
}
.img_logos:hover{
    filter: saturate(1);
    transform: scale(0.90);
}

@media screen and (max-width: 768px) {
    .carousel__container{
        padding-left: 0;
        padding-right: 0;
    }
    .img_logos{
        max-width: 90%;
        filter: saturate(1);
    }

}