* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  ul,li{
    color: #fff;
    list-style: none;
  }
  a{
    text-decoration: none;
  }

  html{
    scroll-behavior: smooth;
  }
  