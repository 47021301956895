.main{
    width: 100vw;
}

.projects_container{
    padding: 100px 0 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
}

.projects{
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
}


.title_section{
    font-size: 2.5rem;
    font-weight: bold;
    color: #123BA5;
    text-align: center;
    align-self: center;
    margin-bottom: 6rem;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
}

.projects_carousel{
    width: 90vw;
}

.project{
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: flex-start;
}

.project_img{
    max-width: 90%;
    height: auto;
    border-radius: 20px;
    transition: all 0.5s ease;
}

.project_img:hover{
    max-width: 95%;
}

.project_place{
    height: 3.5rem;
    max-width: 80%;
    word-wrap: break-word;
    padding-top: 1rem;
    padding-bottom: .5rem;
    display: flex;
    flex-direction: row;
    color: #FF7800;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: bold;
}

p{
    margin-left: .5rem;
}

.project_title{
    padding-left: 1rem;
    max-width: 85%;
    font-size: 18px;
    font-weight: bold;
    color: #123BA5;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif;
    word-wrap: break-word;
}

@media screen and (max-width: 960px){
    .project_place{
        max-width: 100%;
    }
    .project_img{
        max-width: 100%;
    }
    .project_img:hover{
        max-width: 100%;
    }
    p{
        margin-left: 0;
    }
    .project_title{
        text-align: justify;
        max-width: 100%;
    }
}